import _pages from '../data/pages'
import _posts from '../data/posts'
import _about from '../data/about'
import _companyInfo from '../data/companyInfo'
import { combineReducers } from 'redux';

const pages = (state = _pages) => {
    return state
}
const posts = (state = _posts) => {
    return state
}
const about = (state = _about) => {
    return state
}

const companyInfo = (state = _companyInfo) => {
    return state
}

const rootReducer = combineReducers({pages, posts, companyInfo, about});

export default rootReducer