import Post from './Post'

let postwall = (props) => {
    return(
        <div className='postwall'>
            {props.posts.map((post, index) => 
            !post.carousel
            ? <Post key={index} currentPost={post}/>
            : null
            )}
        </div>
    )
}

export default postwall