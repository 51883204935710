import { Link } from 'react-router-dom';

let post = (props) => {
    let classNames = "post"
    if (props.currentPost.span === 2) {
        classNames += ' span-two'
    }
    return (
        <div className={classNames}>
            <div className="stacked">
                <img className="post-img" src={props.currentPost.photo}></img>
                <div className="overlay"></div>
                <div className="post__content-container">
                    <div className="post__content img-text">{props.currentPost.text}</div>
                    <div className="post__content sub-text">{props.currentPost.subtext}</div>
                    {props.currentPost.needsButton && props.currentPost?.internalLink &&
                        <Link to={props.currentPost.internalLink}>
                            <button className="post__content post-button">{props.currentPost.buttonText}</button>
                        </Link>
                    }
                    {props.currentPost.needsButton && props.currentPost?.externalLink &&
                        <a href={props.currentPost.externalLink} target='_blank'>
                            <button className="post__content post-button">{props.currentPost.buttonText}</button>
                        </a>
                    }
                </div>
                {props.currentPost.title != undefined && <div className="title-container">
                    <div className="post__content title">{props.currentPost.title}</div>
                </div>}
            </div>

        </div>
    )
}

export default post