import React from 'react';
import ReactDOM from 'react-dom';
import './styles/nav-bar.css';
import './styles/posts.css';
import './styles/construction.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/carousel.css';
import './styles/footer.css';
import './Components/index.css';
import App from './Components/App';
import {BrowserRouter} from 'react-router-dom';
import {createStore} from 'redux';
import rootReducer from './redux/reducer';
import {Provider} from 'react-redux';

const store = createStore(rootReducer);

ReactDOM.render(
      <Provider store={store}>
              <BrowserRouter>
                  <App/>
              </BrowserRouter>
      </Provider>
            , document.getElementById('root'));

