const posts = [
    { photo: "./static/carousel-photos/seascape.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/seascape2.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/cityscape.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true , needsButton: false},
    { photo: "./static/carousel-photos/cityscape2.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/ice-road.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true , needsButton: false},
    { photo: "./static/carousel-photos/pondscape.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true , needsButton: false},
    { photo: "./static/carousel-photos/iceland-sea-cliffscape.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/sandscape.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/hillscape.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/clouds1.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    // {photo: "./static/carousel-photos/clouds2.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true},
    // {photo: "./static/carousel-photos/clouds3.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true},
    { photo: "./static/carousel-photos/clouds4.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    // {photo: "./static/carousel-photos/clouds5.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true},
    // {photo: "./static/carousel-photos/clouds6.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true},
    { photo: "./static/carousel-photos/foggy-clouds.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/beach-sunset.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/yosemite.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/carousel-photos/windos-field.jpg", text: "International Children\'s Outreach", subtext: "\"...among whom you shine as lights in the world\"", span: 2, carousel: true, needsButton: false },
    { photo: "./static/media/owc-poster.jpeg", subtext: "ICO shares the gospel message and basic Bible truths with children and families", span: 2, carousel: false, needsButton: true, buttonText: "About ICO", internalLink: "/About" },
    { photo: "./static/media/desi-as-joy.jpg", subtext: "Through fun events, children and adults can learn more about the God's word and His love", span: 2, carousel: false, needsButton: true, buttonText: "Events" , externalLink: "https://icelandchristianhistory.com" },
    { photo: "./static/media/braids.JPG", subtext: "Check out some of our favorite resources available for children's ministry, teachers and leaders", span: 2, carousel: false, needsButton: true, buttonText: "Resources" , internalLink: "/Resources" },
    { photo: "./static/media/wordlessbook.jpg", subtext: "Enjoy a special message with the Wordless Book", span: 2, carousel: false, needsButton: true, buttonText: "Wordless Book", internalLink: "/Wordless"  }
    // {photo: "./static/angela-teaching.png", text: "If only I had my felt", span: 2,  carousel: true}
    // {photo: "./static/braids.jpg", text: "Look at my braids", span: 2},

    // {photo: "./static/balloon animal.JPG", text: "Ruf, arf, pop!", span: 1}
    // {photo: "./static/Lila at club.jpg", text: "Cheese :)", span: 2}
]

export default posts