import Header from './Header.js'
import contactStyles from './Contact.module.css'
let contactSuccess = (props) => {
    return(
        <div className={contactStyles.contact_success}>
            <h1 className='success-message'>Your Email Was Sent Successfully</h1>
        </div>
    )
}

export default contactSuccess