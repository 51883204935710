const about = [
    { photo: "./static/about/parents.png", title: "James and Angela Joransen began ICO with a focus on teaching children and families in basic Bible truths. They are artist-educators currently living in Iceland.", span: 2, needsButton: false },
    { photo: "./static/about/harold.jpeg", title: "Harold Sprayberry leads Bad News Good News Bible clubs in Mississippi.", span: 2, needsButton: false },
    // { photo: "./static/about/katherine.jpeg", title: "Katherine Van Lengen", span: 1, needsButton: false },
    // { photo: "./static/about/desi.jpeg", title: "Desiree Joransen", span: 1, needsButton: false },
    // { photo: "./static/about/schrag.jpeg", title: "Adrienne and Eric Schrag", span: 1, needsButton: false },
    // { photo: "./static/about/boys.jpeg", title: "P J Jones, Nicholas Joransen, Andrew Sorrow", span: 1, needsButton: false },
    // { photo: "./static/about/joshnizzy.jpeg", title: "Joshua and Izzy Joransen", span: 1, needsButton: false },
    // { photo: "./static/about/george.jpeg", title: "George Southgate", span: 1, needsButton: false },
    // { photo: "./static/about/johns.jpeg",title: "Johan and Gabrielle John", span: 1, needsButton: false },
    // { photo: "./static/about/tate.jpeg", title: "Lizzy Tate", subtext: "",span: 1, needsButton: false },
]

export default about