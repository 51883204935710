import { Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";
import ContactForm from './ContactForm.js'
import ContactSuccess from './ContactSuccess.js'
import contactStyle from './Contact.module.css';

let Contact = (props) => {
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const firstNameInputRef = useRef()
    const lastNameInputRef = useRef()
    const emailInputRef = useRef()
    const messageInputRef = useRef()

    let inputField = (refName, id, placeholder, className) => {
        return (<input
                    onChange={handleFocus}
                    onBlur={handleBlur}
                    ref={refName}
                    className={className}
                    type="text"
                    id={id}
                    placeholder={placeholder}>
                </input>)
    }

    let sendEmail = (e) => {
        const firstName = e.target.firstName.value
        const lastName = e.target.lastName.value
        const emailAddress = e.target.emailAddress.value
        const message = e.target.message.value

        const validEmail = emailAddress.match('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
        if (!validEmail || !firstName || !lastName || !message) {
            return shouldShowError(true);
        }
        fetch(`https://jqz4oaei2wzddw2vyd4einsrfe0fwpvp.lambda-url.us-east-1.on.aws/?firstName=${firstName}&lastName=${lastName}&message=${message}&emailAddress=${emailAddress}`)
        shouldShowSuccess()
    }

    let shouldShowSuccess = () => {
        setShowSuccess(true)
    }

    let shouldShowError = (error) => {
        setShowError(error)
    }

    let showFormError = () => {
        if (showError) {
            return (
                <h1 className={contactStyle.invalid_input}>
                    Please Fill Out All Fields And Enter A Valid Email
                </h1>
            )
        }
        return <h1></h1>
    }

    let handleEmail = (e) => {
        e.preventDefault()
        shouldShowError(false)
        sendEmail(e);
    }

    let handleFocus = (e) => {
        if (e.target.id === "firstName" && firstNameInputRef.current.value.length > 0) {
            return firstNameInputRef.current.classList.remove(contactStyle.contact_name_red_firstname)
        }
        if (e.target.id === "lastName" && lastNameInputRef.current.value.length > 0) {
            return lastNameInputRef.current.classList.remove(contactStyle.contact_name_red_lastname)
        }
        if (e.target.id === "emailAddress" && emailInputRef.current.value.length > 0) {
            return emailInputRef.current.classList.remove(contactStyle.red_border)
        }
        if (e.target.id === "message" && messageInputRef.current.value.length > 0) {
            return messageInputRef.current.classList.remove(contactStyle.red_border)
        }
    }

    let handleBlur = (e) => {
        if (e.target.id === "firstName" && firstNameInputRef.current.value.length === 0) {
            return firstNameInputRef.current.classList.add(contactStyle.contact_name_red_firstname)
        }
        if (e.target.id === "lastName" && lastNameInputRef.current.value.length === 0) {
            return lastNameInputRef.current.classList.add(contactStyle.contact_name_red_lastname)
        }
        if (e.target.id === "emailAddress" && emailInputRef.current.value.length === 0) {
            return emailInputRef.current.classList.add(contactStyle.red_border)
        }
        if (e.target.id === "message" && messageInputRef.current.value.length === 0) {
            return messageInputRef.current.classList.add(contactStyle.red_border)
        }
    }

    if (!showSuccess) {
        return (
            <div className={contactStyle.form_container}>
                {showFormError()}
                <form className={contactStyle.contact_form} onSubmit={handleEmail}>
                    <div className={contactStyle.contact_names}>
                        <div className={contactStyle.form_item}>
                            {inputField(firstNameInputRef, "firstName", "First Name", contactStyle.contact_name)}
                        </div>
                        <div className={contactStyle.form_item}>
                            {inputField(lastNameInputRef, "lastName", "Last Name", contactStyle.contact_name)}
                        </div>
                    </div>
                    <div className={contactStyle.form_item}>
                        {inputField(emailInputRef, "emailAddress", "Email Address", contactStyle.contact_field)}
                    </div>
                    <div className={contactStyle.form_item}>
                        <textarea
                            onChange={handleFocus}
                            onBlur={handleBlur}
                            ref={messageInputRef}
                            className={contactStyle.message_box}
                            id="message"
                            placeholder="Write Message Here...">
                        </textarea>
                    </div>
                    <div className={contactStyle.form_item}>
                        <button
                            className={contactStyle.form_button}
                            type="submit">
                            Send
                        </button>
                    </div>
                </form>
            </div>
        )
    }
    return <ContactSuccess {...props} />

}


export default Contact