import Post from './Post'
import { Carousel, CarouselItem } from 'react-bootstrap';

let CarouselContainer = (props) => {
    let carouselPosts = [];

    let getCarouselPosts = (props) => {
        props.posts.map((post, index) => {
            if (post.carousel) {
                carouselPosts.push(post)
            }
        })
    }
    getCarouselPosts(props)

    return (
            <Carousel controls={false} indicators={false}>
                {carouselPosts.map((post, index) => {
                    return (
                        <CarouselItem key={index} interval={5000}>
                            <Post key={index} index={index} currentPost={post} />
                        </CarouselItem>
                    )
                })}
            </Carousel>
    )
}


export default CarouselContainer