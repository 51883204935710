import Post from './Post'
import Postwall from './Postwall.js'
import React from 'react'

let About = (props) => {
  return (
    <div>
        <Postwall posts={props.about}/>
    </div>
  )
}

export default About