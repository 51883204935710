export function loadPages(pages) {
    return {
        type: 'LOAD_PAGES',
        pages
    }
}
export function loadPostss(posts) {
    return {
        type: 'LOAD_POSTS',
        posts
    }
}

export function loadAbout(about) {
    return {
        type: 'LOAD_ABOUT',
        about
    }
}

export function loadCompanyInfo(companyInfo) {
    return {
        type: 'LOAD_COMPANY_INFO',
        companyInfo
    }
}