import { Link } from 'react-router-dom';
import logo from './static/ico-logo-white.png' 
let header = (props) => {
    return (
        <div>
            <header className="site-header">
                <Link to="/"><img src={logo} alt='ICO'></img></Link>
                <input type="checkbox" id='nav-toggle' className='nav-toggle'></input>
                <nav>
                    <ul>
                        {
                            props.pages.map((page, index) => 
                            page.path 
                            ? <li key={index}><Link  to={page.path}>{page.title}</Link></li> 
                            : <li key={index}><a href={page.href} target="_blank" rel="noopener noreferrer">{page.title}</a></li>)
                            
                        }
                    </ul>
                </nav>
                <label htmlFor='nav-toggle' className='nav-toggle-label'><span></span></label>
            </header>

        </div>
    )
}


export default header