

let Construction = () => {
    return(
        <div className="construction">
            <div>This page is under construction</div>            
        </div>
    )
}

export default Construction